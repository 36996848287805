<template>
    <div class="animated fadeIn faster">
        <div class="titlebar-con">
            <ar-titlebar
                campaign="Teams Management"
                :from_count="from_page_count"
                :to_count="to_page_count"
                :max_count="visible_total"
                subject="Team(s)"
                is_listings
                :has_image="false"
            >
                <template v-slot:extra>
                    <span
                        v-restrict="'a-link-add-team'"
                        class="a-link-add-team"
                    >
                        <ar-button
                            icon   ="fas fa-plus"
                            @click ="showAddTeamModal()"
                            :is_fab ="true"
                            style  ="padding-top: 8px; padding-bottom: 8px;"
                            class  ="ml-3"
                        >
                            Add
                        </ar-button>
                    </span>
                </template>
                <template v-slot:search-form>
                    <div class="form-inline">
                        <div class="a-input-group">
                            <ar-textfield 
                                placeholder         ="Search Teams here" 
                                type                ="text"
                                a_class             ="mr-1"
                                a_style             ="width: 300px;"
                                icon                ="fas fa-search"
                                v-model.trim        ="search"
                            />
                        </div>
                    </div>
                </template>
            </ar-titlebar>
        </div>

        <ar-dialog 
            id="deactivate-team-modal"
            :heading="`${current.status == 1 ? 'Deactivate' : 'Activate'} Team`"
            :heading_style="current.status == 1 ? 'color: #DC3C40;' : 'color: #117b0a'"
            :sub_heading="current.status == 1 ? 'You are about to deactivate this team. Click \'Proceed\' to continue to deactivation.' :
                                                'You are about to activate this team. Click \'Proceed\' to continue to activation.'"
        >
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined>
                    Cancel
                </ar-button>
                <ar-button @click="deOrActivate()">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="new-team-modal" large>
            <div class="add-new-con" :class="{'side-padding': is_available !== true}">
                <h5>
                    {{ edit_mode ? 'Update Team' : 'Add Team' }}
                </h5>
                <template v-if="is_available !== true">
                    <ar-textfield 
                        placeholder         ="Input Name"
                        label               ="Team Name"
                        type                ="text"
                        a_class             ="mr-1"
                        :state              ="!new_name ? 'invalid': null"
                        :err_txt            ="!new_name ? 'This field is required' : null"
                        v-model.trim        ="new_name"
                        @enter              ="checkAvailability()"
                    />

                    <template v-if="is_available === null">
                        <div class="animated fadeIn">
                            <label class="mt-5 mb-0 d-block" style="color: #601848; font-size: 16px;">Before Adding,</label>
                            <p style="font-size: 16px;">let us check if the inputted Team already existed in the database</p>
                            <ar-button @click="checkAvailability()" :disabled="!new_name">Check Availability</ar-button>
                        </div>
                    </template>
                    <template v-else-if="is_available === false">
                        <div class="animated fadeIn">
                            <label class="mt-5 mb-0 d-block" style="color: #DC1A1A; font-size: 16px;">Team Name is already in the database,</label>
                            <p style="font-size: 16px;">you can't add this, please try other new Team to add.</p>
                            <ar-button 
                                class="accent"
                                @click="is_available = null"
                            >
                                Try Other Team Name
                            </ar-button>
                        </div>
                    </template>

                    <template v-if="is_available === true || is_available === null">
                        <img src="/static/svg/Database_Photo.svg" alt="Woman standing beside bars bars">
                    </template>
                    <template v-else-if="is_available === false">
                        <div class="availability-card-con">
                            <ar-card 
                                :has_image="false"
                                :card_info="convertToCard(existing)"
                                card_type="sm"
                                class="animated fadeIn fast"
                                :active="false"
                            />
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="campaign-additional-info animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <ar-textfield 
                                    a_type="text"
                                    label="Team Name"
                                    placeholder="Input here"
                                    style="margin-bottom: 0"
                                    err_txt="This Team Name is Available"
                                    v-model="current.name"
                                    disabled
                                />
                            </div>
                            <div class="col-md-6 align-self-center" style="margin-top: 3px">
                                <ar-button 
                                    outlined
                                    @click="is_available = null"
                                >
                                    Change Team
                                </ar-button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <ar-textfield 
                                    a_type="text"
                                    label="Description"
                                    placeholder="Input description here"
                                    v-model.trim="current.description"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <ar-select 
                                    label="Team/Campaign Lead"
                                    v-model="current.lead"
                                    :val="current.lead"
                                    :options="team_leads"
                                    :invalid="!current.lead || current.lead == 0"
                                >
                                    Select here
                                </ar-select>
                            </div>
                            <div class="col-md-6">
                                <ar-textfield 
                                    a_type="text"
                                    label="Schedule"
                                    placeholder="Input schedule here"
                                    v-model.trim="current.schedule"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="custom-control custom-checkbox" style="margin-top: 38px;">
                                    <input class="custom-control-input" type="checkbox" name="set-active" id="set-active" v-model="current.status" checked>
                                    <label for="set-active" class="custom-control-label font-weight-normal text-left" style="font-size: 14px; color: #515365;">Set this as Active</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <template v-if="is_available === true" v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined>
                    Cancel
                </ar-button>
                <ar-button
                    @click="edit_mode ? update() : add()"
                    :disabled="!current.lead || current.lead == 0"
                >
                    {{ edit_mode ? 'Save Changes' : 'Add Now' }}
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog
            id="user-assignment-modal"
            large
            status_dialog
            :has_button="false"
            :has_footer="false"
        >
            <div class="row text-left justify-content-between">
                <div class="col-md-6 pr-4 border-right">
                    <div class="mb-2">
                        <img src="/static/svg/Team.svg" class="d-inline-block align-middle campaign-img">
                        <h5 class="d-inline-block ml-2 align-middle">
                            {{ this.current.name }}
                            <span class="d-block font-weight-normal">
                                {{ this.team_members ? this.team_members.length : 0 }} Team Members
                            </span>
                        </h5>
                    </div>
                    <div class="a-input-group">
                        <ar-textfield 
                            placeholder         ="Search Users here"
                            type                ="text"
                            icon                ="fas fa-search"
                            v-model.trim        ="assign_search"
                        />
                    </div>
                    <div class="left-con" style="max-height:430px">
                        <div class="user-list" v-if="team_members">
                            <div v-if="filtered_assign_members.length > 0">
                                <div class="user-con" v-for="(user_id, index) in filtered_assign_members" :class="{'remove-confirm-con':active_index == index && confirm_remove}" :key="index" @click="active_index = index">
                                    <div :class="[{'bg-light': current_user === user_id}, 'a-modal-seller-info-item']">
                                        <img :src="$parent.getImage('users', user_roles[users[user_id].access_level])" alt="icon">
                                        <h5>
                                            {{ `${users[user_id].first_name || ''} ${users[user_id].last_name || ''}` }}
                                            <span>
                                                {{ user_roles[users[user_id].access_level] }}
                                            </span>
                                        </h5>
                                        <template v-if="!(active_index == index && confirm_remove)">
                                            <a title="Assign Campaign to this user" class="assign-btn" @click="confirm_remove = false; showAssignCampaign(user_id)">
                                                <img src="/static/svg/Team_Campaign.svg" alt="icon">
                                            </a>
                                            <a title="Unassign this user" class="remove-btn" @click="confirm_remove = true">
                                                <img src="/static/svg/Team_Remove.svg" alt="icon">
                                            </a>
                                        </template>
                                    </div>
                                    <hr class="m-0"/>
                                    <template v-if="active_index == index && confirm_remove">
                                        <div class="d-flex animated fadeIn justify-content-between align-items-center p-2">
                                            <p class="text-danger remove-confirm-text">Please confirm to remove this member.</p>
                                            <div>
                                                <a title="Cancel" class="remove-confirm-btn position-relative" @click="confirm_remove = false">
                                                    <img src="/static/svg/Remove_Cancel.svg" alt="icon">
                                                </a>
                                                <a title="Confirm" class="remove-confirm-btn position-relative ml-2" @click="unassignUser(user_id)">
                                                    <img src="/static/svg/Remove_Confirm.svg" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <hr class="m-0"/>
                                    </template>
                                </div>
                            </div>
                            <div v-else>
                                No members found
                            </div>
                        </div>
                        <div v-else>
                            Loading...
                        </div>
                    </div>
                </div>
                <div class="col-md-6 pl-4">
                    <h5 class="mb-3" style="font-size: 16px; color: #515365;">{{ assign_campaign_mode ? 'Campaigns Assignment' : 'Add Members to this Team' }}<span class="d-block font-weight-normal" style="font-size: 12px;">Select {{ assign_campaign_mode ? 'Campaigns below to assign' : 'Users below' }}</span></h5>
                    <div class="a-input-group">
                        <ar-textfield 
                            :placeholder        ="`Search ${assign_campaign_mode ? 'Campaign' : 'Users'} here`"
                            type                ="text"
                            icon                ="fas fa-search"
                            v-model.trim        ="unassign_search"
                        />
                    </div>
                    <div class="right-con" style="max-height:430px;">
                        <template v-if="assign_campaign_mode">
                            <div v-if="user_campaigns">
                                <div v-if="filtered_user_campaigns.length > 0">
                                    <div v-for="(campaign_id, index) in filtered_user_campaigns" :key="index" class="custom-control custom-checkbox mb-1">
                                        <input
                                            v-model="checked_campaigns"
                                            class="custom-control-input"
                                            type="checkbox"
                                            name="unassigned-campaigns" 
                                            :id="`campaign-${campaign_id}`"
                                            :value="campaign_id"
                                        />
                                        <label
                                            :for="`campaign-${campaign_id}`"
                                            class="custom-control-label font-weight-normal text-left"
                                            style="font-size: 15px"
                                        >
                                            <img style="height: 18px; width: 18px;" class="mr-2" :src="$parent.getImage('campaigns', campaigns[campaign_id].name)" alt="icon">
                                            {{ campaigns[campaign_id].name }}
                                        </label>
                                    </div>
                                </div>
                                <div v-else>
                                    No results found
                                </div>
                            </div>
                            <div v-else>
                                Loading...
                            </div>
                        </template>
                        <template v-else>
                            <div v-if="unassign_users && unassign_users.length > 0">
                                <div v-if="filtered_unassign_users.length > 0">
                                    <div v-for="(user_id, index) in filtered_unassign_users" :key="index" class="custom-control custom-checkbox mb-1">
                                        <input
                                            v-model="checked_users"
                                            class="custom-control-input"
                                            type="checkbox"
                                            name="unassigned-users" 
                                            :id="`user-${user_id}`"
                                            :value="user_id"
                                        />
                                        <label
                                            :for="`user-${user_id}`"
                                            class="custom-control-label font-weight-normal text-left"
                                            style="font-size: 15px"
                                        >
                                            <img style="height: 18px; width: 18px;" class="mr-2" :src="$parent.getImage('users', user_roles[users[user_id].access_level])" alt="icon">
                                            {{ `${users[user_id].first_name || ''} ${users[user_id].last_name}` }}
                                        </label>
                                    </div>
                                </div>
                                <div v-else>
                                    No results found
                                </div>
                            </div>
                            <div v-else>
                                Loading...
                            </div>
                        </template>
                    </div>
                    <ar-button 
                        v-if="assign_campaign_mode"
                        @click="assign_campaign_mode = false; current_user = null; unassign_search = null; checked_users = []; checked_campaigns = []"
                        class="float-right mt-3"
                        outlined>
                        Close
                    </ar-button>
                    <ar-button
                        class="float-right mt-3 mr-2"
                        v-show="checked_users.length || checked_campaigns.length"
                        @click="assign_campaign_mode ? assignCampaignToUser() : assignUser()"
                        :disabled="processing_campaign || processing_user"
                    >
                        {{ assign_campaign_mode ? 'Assign Campaign' : 'Assign User' }}
                    </ar-button>
                </div>
            </div>
        </ar-dialog>

        <ar-dialog
            id="campaign-assignment-modal"
            large
            status_dialog
            :has_button="false"
            :has_footer="false"
        >
            <div class="row text-left justify-content-between">
                <div class="col-md-6 pr-4 border-right">
                    <div class="mb-2">
                        <img src="/static/svg/Team.svg" class="d-inline-block align-middle campaign-img">
                        <h5 class="d-inline-block ml-2 align-middle">
                            {{ current.name }}
                            <span class="d-block font-weight-normal">
                                {{ team_campaigns ? team_campaigns.length : 0 }} Assigned Campaigns
                            </span>
                        </h5>
                    </div>
                    <div class="a-input-group">
                        <ar-textfield 
                            placeholder         ="Search Campaigns here"
                            type                ="text"
                            icon                ="fas fa-search"
                            v-model.trim        ="assign_search"
                        />
                    </div>
                    <div class="left-con" style="max-height:430px">
                        <div class="user-list" v-if="team_campaigns">
                            <div v-for="(campaign_id, index) in filtered_assign_campaigns" :key="index">
                                <div class="a-modal-seller-info-item">
                                    <img :src="$parent.getImage('campaigns', campaigns[campaign_id].name)" alt="icon">
                                    <h5>{{ campaigns[campaign_id].name }}<span>{{ campaigns[campaign_id].lawfirm_name }}</span></h5>
                                    <a title="Unassign this Campaign" class="remove-btn" @click="unassign(campaign_id)">
                                        <img src="/static/svg/Remove_List.svg" alt="icon">
                                    </a>
                                </div>
                                <hr class="m-0"/>
                            </div>
                        </div>
                        <div v-else>
                            Loading...
                        </div>
                    </div>
                </div>
                <div class="col-md-6 pl-4">
                    <h5 class="mb-3" style="font-size: 16px; color: #515365;">Campaigns Assignment <span class="d-block font-weight-normal" style="font-size: 12px;">Select Campaigns below to assign</span></h5>
                    <div class="a-input-group">
                        <ar-textfield 
                            placeholder         ="Search Campaigns here"
                            type                ="text"
                            icon                ="fas fa-search"
                            v-model.trim        ="unassign_search"
                        />
                    </div>
                    <div class="right-con" style="max-height:430px;" v-if="team_campaigns">
                        <div v-for="(campaign, index) in filtered_unassign_campaigns" :key="index">
                            <div class="custom-control custom-checkbox mb-1">
                                <input class="custom-control-input" type="checkbox" v-model="checked_campaigns" name="campaigns" :id="`campaign-${campaign.id}`" :value="campaign.id">
                                <label :for="`campaign-${campaign.id}`" class="custom-control-label font-weight-normal text-left" style="font-size: 15px">
                                    <img style="height: 18px; width: 18px;" class="mr-2" :src="$parent.getImage('campaigns', campaign.name)" alt="icon">
                                    {{ campaign.name }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="right-con" v-else>
                        Loading...
                    </div>
                    <ar-button class="float-right mt-3" :disabled="processing_campaign" v-if="checked_campaigns.length" @click="assign()">
                        Assign Campaign
                    </ar-button>
                </div>
            </div>
        </ar-dialog>

        <main class="main-con">
            <div class="card-con">
                <ar-card 
                    v-for="(team,index) in filtered_teams.result" :key="index"
                    :has_image="false"
                    :card_info="convertToCard(team)"
                    :active="team.status == 1 || team.status == true"
                    card_type="sm"
                    class="animated fadeIn fast"
                    has_hovered_options
                >
                    <template v-slot:hovered-options>
                        <a 
                            class="actions a-link-assign-members-to-team"
                            href="javascript:void(0);"
                            title="Assign Members to this Team"
                            @click="showMembersAssignment(team)"
                            v-restrict="'a-link-assign-members-to-team'"
                        >
                            <img src="/static/svg/Add_Members.svg" alt="icon">
                        </a>
                        <a 
                            class="actions a-link-assign-campaign-to-team"
                            href="javascript:void(0);"
                            title="Assign Campaign"
                            @click="showCampaignsAssignment(team)"
                            v-restrict="'a-link-assign-campaign-to-team'"
                        >
                            <img src="/static/svg/Team_CampaignsAssignment.svg" alt="icon">
                        </a>
                        <a 
                            class="actions a-link-edit-team"
                            href="javascript:void(0);"
                            title="Edit Team"
                            @click="showEditModal(team)"
                            v-restrict="'a-link-edit-team'"
                        >
                            <img src="/static/svg/Seller_Edit.svg" alt="icon">
                        </a>
                        <a
                            v-if="team.status === 1 || team.status === true"
                            class="actions a-link-change-status-team"
                            href="javascript:void(0);"
                            title="Deactivate Team"
                            @click="showDeOrActivateModal(team)"
                            v-restrict="'a-link-change-status-team'"
                        >
                            <img src="/static/svg/RH_Deactivate.svg" alt="icon">
                        </a>
                        <a
                            v-if="team.status === 0 || team.status === false"
                            class="actions a-link-change-status-team"
                            href="javascript:void(0);"
                            title="Activate Team"
                            @click="showDeOrActivateModal(team)"
                            v-restrict="'a-link-change-status-team'"
                        >
                            <img src="/static/svg/RH_Activate.svg" alt="icon">
                        </a>
                    </template>
                </ar-card>
            </div>
        </main>
        <footer class="pagination-footer">
            <div class="d-flex justify-content-between">
                <ar-select 
                    v-model="page_size"
                    :options="page_size_options"
                    :first_element_as_default="true"
                    style="z-index: 1"
                    @input="current_page = 1">
                </ar-select>
                <ar-pagination
                    :page_no="current_page"
                    :last_page="last_page"
                    @prev-click="current_page--"
                    @next-click="current_page++"
                    @first-click="current_page = 1"
                    @last-click="current_page = last_page"
                    @enter="gotoPage"
                    @keypress.native="isNumber"
                />
            </div>
        </footer>
    </div>
</template>

<script>
import textfield from '../../layouts/Textfield'
import titlebar from '../../layouts/TitleBar'
import select from '../../layouts/Select'
import axios from '../../../axiosMime'
export default {
    name: 'ar-teams',
    components: {
        'ar-textfield': textfield,
        'ar-titlebar': titlebar,
        'ar-select': select,
        ArCard: () => import('../../layouts/Card'),
        ArButton: () => import('../../layouts/Button'),
        ArDialog: () => import('../../layouts/Dialog'),
        ArPagination: () => import('../../layouts/Pagination')
    },
    data() {
        return {
            search: null,
            assign_search: null,
            unassign_search: null,
            teams: [],
            current: {},
            current_copy: {},
            current_user: null,
            existing: {},
            new_name: null,
            edit_mode: false,
            visible: 0,
            visible_total: 0,
            page_size: 12,
            current_page: 1,
            status: 1,//1 = active, 0 = inactive, -1 = all
            order: 'asc',//asc, desc
            is_available: null,
            team_campaigns: [],
            team_members: [],
            users: [],
            unassign_users: null,
            campaigns: [],
            user_campaigns: [],
            checked_campaigns: [],
            checked_users: [],
            processing_campaign: false,
            processing_user: false,
            assign_campaign_mode: false,
            user_roles: {
                1: 'Researcher',
                2: 'Quality Assurance',
                3: 'Lawyer',
                4: 'Administrator',
                5: 'Campaign Lead',
                6: 'Client',
                7: 'Super Admin'
            },
            page_size_options: [
                {
                    label: 'Show 12 items',
                    value: 12
                },
                {
                    label: 'Show 30 items',
                    value: 30
                },
                {
                    label: 'Show 60 items',
                    value: 60
                },
                {
                    label: 'Show 120 items',
                    value: 120
                }
            ],
            active_index: null,
            confirm_remove: false,
        }
    },
    mounted() {
        this.setFilter();
        this.get();
        this.getUsers();
    },
    watch: {
        '$route.query'() {
            this.current_page = 1;
            this.setFilter();
        },
        '$route.query.order'() {
            this.sort();
        },
        filtered_teams(new_val) {
            this.visible = new_val.visible;
            this.visible_total = new_val.visible_total;
        }
    },
    computed: {
        last_page() {
            return Math.ceil(this.visible_total / parseInt(this.page_size));
        },
        from_page_index() {
            return (this.current_page - 1) * parseInt(this.page_size);
        },
        to_page_index() {
            return (this.from_page_index + parseInt(this.page_size));
        },
        from_page_count() {
            return (this.current_page * parseInt(this.page_size)) - parseInt(this.page_size) + 1;
        },
        to_page_count() {
            return (this.current_page * parseInt(this.page_size)) - (parseInt(this.page_size) - this.visible);
        },
        team_leads() {
            let leads = this.teams.map(t => t.lead);
            let users = Object.values(this.users).filter(u => [5, 7].includes(u.access_level) && !leads.includes(u.id));
            return users.map(u => ({label: `${this.trim(u.first_name)} ${this.trim(u.last_name)}`, value: u.id}));
        },
        filtered_teams() {
            if (this.status === -1) {
                let teams = this.teams;
                if (this.search) {
                    let search = this.search.toLowerCase();
                    teams = teams.filter(c =>
                        (c.name && c.name.toLowerCase().includes(search)) ||
                        (c.description && c.description.toLowerCase().includes(search)) ||
                        (c.lead_name && c.lead_name.toLowerCase().includes(search))
                    );
                }
                let result = teams.slice(this.from_page_index, this.to_page_index);
                let visible_total = teams.length;
                let visible = result.length;
                return { result, visible_total, visible };
            }
            else if (!this.search) {
                let teams = this.teams.filter(c => c.status == this.status);
                let visible_total = teams.length;
                let result = teams.slice(this.from_page_index, this.to_page_index);
                let visible = result.length;
                return { result, visible_total, visible };
            }
            else {
                let search = this.search.toLowerCase();
                let teams = this.teams.filter(
                                    c => c.status == this.status
                                    && (
                                        (c.name && c.name.toLowerCase().includes(search)) ||
                                        (c.description && c.description.toLowerCase().includes(search)) ||
                                        (c.lead_name && c.lead_name.toLowerCase().includes(search))
                            ));
                let visible_total = teams.length;
                let result = teams.slice(this.from_page_index, this.to_page_index);
                let visible = result.length;
                return { result, visible_total, visible };
            }
        },
        filtered_assign_campaigns() {
            if (!this.team_campaigns) {
                return [];
            }

            let campaigns = this.team_campaigns.filter(c => this.campaigns[c]);

            if (this.assign_search) {
                let search = this.assign_search.toLowerCase();
                campaigns = campaigns.filter(c => 
                                (this.campaigns[c].name && this.campaigns[c].name.toLowerCase().includes(search)) ||
                                (this.campaigns[c].lawfirm_name && this.campaigns[c].lawfirm_name.toLowerCase().includes(search)));
            }

            return campaigns;
        },
        filtered_unassign_campaigns() {
            let campaigns = Object.values(this.campaigns).filter(c => !this.team_campaigns.includes(c.id));
            if (this.unassign_search) {
                let search = this.unassign_search.toLowerCase();
                campaigns = campaigns.filter(c => 
                                (c.name && c.name.toLowerCase().includes(search)) ||
                                (c.lawfirm_name && c.lawfirm_name.toLowerCase().includes(search)));
            }

            return campaigns;
        },
        filtered_user_campaigns() {
            if (!this.user_campaigns) {
                return [];
            }

            let campaigns = this.team_campaigns.filter(c => this.campaigns[c] && !this.user_campaigns.includes(c));

            if (this.unassign_search) {
                let search = this.unassign_search.toLowerCase();
                campaigns = campaigns.filter(c => 
                                (this.campaigns[c].name && this.campaigns[c].name.toLowerCase().includes(search)) ||
                                (this.campaigns[c].lawfirm_name && this.campaigns[c].lawfirm_name.toLowerCase().includes(search)));
            }

            return campaigns;
        },
        filtered_assign_members() {
            if (!this.team_members) {
                return [];
            }

            let members = this.team_members.filter(c => this.users[c]);

            if (this.assign_search) {
                let search = this.assign_search.toLowerCase();
                members = members.filter(u => 
                                `${this.trim(this.users[u].first_name)} ${this.trim(this.users[u].last_name)}`.toLowerCase().includes(search));
            }

            return members;
        },
        filtered_unassign_users() {
            if (!this.unassign_users) {
                return [];
            }

            let users = this.unassign_users.filter(u => this.users[u] && u != this.current.lead);
            if (this.unassign_search) {
                let search = this.unassign_search.toLowerCase();
                users = users.filter(u => 
                                `${this.trim(this.users[u].first_name)} ${this.trim(this.users[u].last_name)}`.toLowerCase().includes(search));
            }

            return users;
        }
    },
    methods: {
        get() {
            this.$parent.showBackdrop();
            let headers = {headers: {...this.$session.get('admin_headers').headers}};
            return axios
                .get(`${process.env.VUE_APP_URL}/teams?page_size=10000`, headers)
                .then(response => {
                    this.teams = response.data.data;
                    this.sort();
                    this.$parent.hideBackdrop();
                    return true;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.hideBackdrop();
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    return false;
                });
        },
        getCampaigns() {
            if (this.campaigns.length) {
                return;
            }

            let headers = {headers: {...this.$session.get('admin_headers').headers}};
            headers.headers['Where'] = '{"status": 1 }';
            headers.headers['Sort'] = 'name,asc';
            return axios
                .get(`${process.env.VUE_APP_URL}/campaigns-only?page_size=1000`, headers)
                .then(response => {
                    response.data.data.forEach(c => {
                        this.campaigns[c.id] = c;
                    });
                    return true;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    return false;
                });
        },
        getTeamCampaigns() {
            return axios
                .get(`${process.env.VUE_APP_URL}/teams/campaigns/${this.current.id}?page_size=1000`, this.$session.get('admin_headers'))
                .then(response => {
                    this.team_campaigns = response.data.data.map(c => c.campaign_id);
                    this.unassign_search = "1";
                    this.unassign_search = null;
                    return true;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    return false;
                });
        },
        getUserCampaigns(user_id) {
            this.user_campaigns = null;
            return axios
                .get(`${process.env.VUE_APP_URL}/users/campaigns/${user_id}?page_size=100000`, this.$session.get('admin_headers'))
                .then(response => {
                    this.user_campaigns = response.data.data.map(c => c.campaign_id);
                    this.unassign_search = '1';
                    this.unassign_search = null;
                    return true;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    return false;
                });
        },
        getUsers() {
            if (this.users.length) {
                return;
            }

            let headers = {headers: {...this.$session.get('admin_headers').headers}};
            headers.headers['Where'] = '{"status": 1, "access_level": [1,2,5,6,7]}';
            return axios
                .get(`${process.env.VUE_APP_URL}/users?page_size=1000`, headers)
                .then(response => {
                    response.data.data.forEach(u => {
                        this.users[u.id] = u;
                    });
                    return true;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    return false;
                });
        },
        getUnassignUsers() {
            this.unassign_users = null;

            return axios
                .get(`${process.env.VUE_APP_URL}/teams/unassign-members?page_size=1000`, this.$session.get('admin_headers'))
                .then(response => {
                    this.unassign_users = response.data.data.map(u => u.id);
                    return true;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    return false;
                });
        },
        getTeamMembers() {
            return axios
                .get(`${process.env.VUE_APP_URL}/teams/members/${this.current.id}?page_size=1000`, this.$session.get('admin_headers'))
                .then(response => {
                    this.team_members = response.data.data.map(c => c.user_id);
                    this.unassign_search = "1";
                    this.unassign_search = null;
                    return true;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    return false;
                });
        },
        checkAvailability() {
            if (this.is_available !== null) {
                return;
            }

            if (!this.new_name) {
                return;
            }

            if (this.edit_mode && this.new_name === this.current.name) {
                this.$parent.showSnackbar('No changes detected', false, 'info', 'fas fa-info-circle');
                return;
            }

            if (this.edit_mode && this.new_name === this.current_copy.name) {
                this.current.name = this.new_name;
                this.is_available = true;
                return;
            }

            this.$parent.showSnackbar('Checking...', true);
            let headers = {headers: {...this.$session.get('admin_headers').headers}};
            headers.headers['Where'] = `{"name": "${this.new_name}"}`;
            headers.headers['Include-Deleted'] = 1;

            axios
                .get(`${process.env.VUE_APP_URL}/teams`, headers)
                .then(({data}) => {
                    if (data.data.length > 0) {
                        this.existing = data.data[0];
                        this.is_available = false;
                        this.$parent.showSnackbar('Name is not available', false, 'invalid', "fas fa-check-circle");
                    }
                    else {
                        this.is_available = true;
                        this.current.name = this.new_name;
                        this.$parent.showSnackbar('Name is available', false, 'success', "fas fa-check-circle");
                    }
                })
                .catch(err => {
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', "fas fa-check-circle");
                });
        },
        add() {
            let headers = {headers: {...this.$session.get('admin_headers').headers}};
            headers.headers['Include-Resource-Data'] = 1;
            let current = this.current
            if(current.lead){
                current.lead_id = current.lead
                delete current.lead;
            }
            axios
                .post(`${process.env.VUE_APP_URL}/teams`, current, headers)
                .then(({data: {meta: {resource_data}}}) => {
                    this.is_available = null;
                    let new_data = resource_data[0];
                    this.teams.push(new_data);
                    this.sort();
                    $('#new-team-modal').modal('hide');
                })
                .catch(ex => {
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle')
                    $('#new-team-modal').modal('hide');
                });
        },
        update() {
            this.$parent.showSnackbar('Updating....', true);
            let headers = {headers: {...this.$session.get('admin_headers').headers}};
            headers.headers['Include-Resource-Data'] = 1;
            let current = this.current;
            delete current.lead_name;
            if(current.lead){
                current.lead_id = current.lead
                delete current.lead;
            }
            axios
                .patch(`${process.env.VUE_APP_URL}/teams/${current.id}`, current, headers)
                .then(({data: {meta: {resource_data}}}) => {
                    Object.keys(resource_data[0]).map(key => {
                        this.current_copy[key] = resource_data[0][key];
                    });
                    this.sort();
                    $('#new-team-modal').modal('hide');
                    this.$parent.showSnackbar('Updated', false, 'success', 'fas fa-check-circle');
                })
                .catch(ex => {
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                });
        },
        deOrActivate() {
            $('#deactivate-team-modal').modal('hide');
            this.$parent.showBackdrop();
            let headers = {headers: {...this.$session.get('admin_headers').headers}};
            headers.headers['Include-Resource-Data'] = 1;
            axios
                .patch(
                    `${process.env.VUE_APP_URL}/teams/${this.current.id}`,
                    { status: !this.current.status },
                    headers)
                .then(response => {
                    Object.keys(response.data.meta.resource_data[0]).map(key => {
                        this.current[key] = response.data.meta.resource_data[0][key];                            
                    });
                    this.current = {};
                    this.$parent.hideBackdrop();
                })
                .catch(ex => {
                    this.$parent.hideBackdrop();
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', "fas fa-check-circle");
                });
        },
        assign() { 
            this.processing_campaign = true;
            this.$parent.showSnackbar('Assigning....', true);
            axios.post(
                `${process.env.VUE_APP_URL}/teams/assign-campaigns/${this.current.id}`,
                { campaigns: this.checked_campaigns },
                this.$session.get('admin_headers'))
                .then(response => {
                    this.checked_campaigns = [];
                    response.data.data.forEach(u => {
                        this.team_campaigns.unshift(u);
                    });
                    this.$parent.showSnackbar('Assigned successfully', false, 'success', 'fas fa-check-circle');
                    this.processing_campaign = false;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Unable to assign campaign(s). Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    this.processing_campaign = false;
                });
        },
        unassign(campaign_id) {
            this.processing_campaign = true;
            this.$parent.showSnackbar('Removing campaign....', true);
            let headers = {headers: {...this.$session.get('admin_headers').headers}};
            headers.headers['campaign-id'] = campaign_id;
            axios.delete(
                `${process.env.VUE_APP_URL}/teams/unassign-campaign/${this.current.id}`,
                headers)
                .then(response => {
                    let index = this.team_campaigns.indexOf(campaign_id);
                    this.team_campaigns.splice(index, 1);
                    this.$parent.showSnackbar('Removed successfully', false, 'success', 'fas fa-check-circle');
                    this.processing_campaign = false;
                })
                .catch(ex => {
                    this.$parent.showSnackbar('Unable to remove campaign. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    this.processing_campaign = false;
                });
        },
        assignUser() { 
            this.processing_user = true;
            this.$parent.showSnackbar('Assigning....', true);
            axios.post(
                `${process.env.VUE_APP_URL}/teams/assign-members/${this.current.id}`,
                { users: this.checked_users },
                this.$session.get('admin_headers'))
                .then(response => {
                    this.checked_users = [];
                    response.data.data.forEach(u => {
                        this.team_members.unshift(u);
                        let index = this.unassign_users.indexOf(u)
                        this.unassign_users.splice(index, 1);
                    });
                    this.$parent.showSnackbar('Assigned successfully', false, 'success', 'fas fa-check-circle');
                    this.processing_user = false;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Unable to assign user(s). Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    this.processing_user = false;
                });
        },
        unassignUser(user_id) {
            this.processing_user = true;
            this.$parent.showSnackbar('Removing user....', true);
            let headers = {headers: {...this.$session.get('admin_headers').headers}};
            headers.headers['user-id'] = user_id;
            axios.delete(
                `${process.env.VUE_APP_URL}/teams/unassign-member/${this.current.id}`,
                headers)
                .then(response => {
                    let index = this.team_members.indexOf(user_id);
                    this.team_members.splice(index, 1);
                    this.unassign_users.unshift(user_id);
                    this.$parent.showSnackbar('Removed successfully', false, 'success', 'fas fa-check-circle');
                    this.processing_user = false;
                })
                .catch(ex => {
                    this.$parent.showSnackbar('Unable to remove user. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    this.processing_user = false;
                });
        },
        assignCampaignToUser() { 
            this.processing_campaign = true;
            this.$parent.showSnackbar('Assigning....', true);
            axios.post(
                `${process.env.VUE_APP_URL}/users/assign-campaigns`,
                { user_id: this.current_user, campaigns: this.checked_campaigns },
                this.$session.get('admin_headers'))
                .then(response => {
                    this.checked_campaigns = [];
                    response.data.data.forEach(u => {
                        this.user_campaigns.push(u);
                    });
                    this.$parent.showSnackbar('Assigned successfully', false, 'success', 'fas fa-check-circle');
                    this.processing_campaign = false;
                })
                .catch(ex => {
                    console.log(ex);
                    this.$parent.showSnackbar('Unable to assign campaign(s). Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                    this.processing_campaign = false;
                });
        },
        showAddTeamModal() {
            this.edit_mode = false;
            this.is_available = null;
            this.new_name = null;
            this.current = {};
            $('#new-team-modal').modal('show')
        },
        async showMembersAssignment(team) {
            this.assign_search = null;
            this.unassign_search = null;
            this.team_members = null;
            this.current = team;
            this.assign_campaign_mode = false;
            this.checked_campaigns = [];
            this.checked_users = [];
            this.confirm_remove = false;
            $('#user-assignment-modal').modal('show');
            await this.getUsers();
            await this.getUnassignUsers();
            await this.getTeamMembers();
            await this.getCampaigns();
            await this.getTeamCampaigns();
        },
        async showCampaignsAssignment(team) {
            this.assign_search = null;
            this.unassign_search = null;
            this.team_campaigns = null;
            this.current = team;
            $('#campaign-assignment-modal').modal('show')
            await this.getCampaigns()
            await this.getTeamCampaigns()
        },
        showEditModal(team) {
            this.edit_mode = true;
            this.is_available = true;
            this.new_name = team.name;
            this.current = {...team};
            this.current_copy = team;
            $('#new-team-modal').modal('show')
        },
        showDeOrActivateModal(team) {
            this.current = team;
            $('#deactivate-team-modal').modal('show');
        },
        showAssignCampaign(user_id) {
            if (this.current_user == null || user_id == this.current_user) {
                this.assign_campaign_mode = !this.assign_campaign_mode;
            }
            this.unassign_search = null;
            this.checked_campaigns = [];
            this.checked_users = [];
            if (this.assign_campaign_mode) {
                this.getUserCampaigns(user_id);
                this.current_user = user_id;
            }
            else {
                this.current_user = null;
            }
        },
        setFilter() {
            this.status = this.$route.query.status === undefined ? 1 : parseInt(this.$route.query.status);
            this.order = this.$route.query.order === undefined ? 'asc' : this.$route.query.order;
        },
        sort() {
            if (this.order === 'asc') {
                this.teams.sort(function(a, b) {
                    let a1 = a.name.toLowerCase();
                    let b1 = b.name.toLowerCase();
                    if (a1 < b1) {
                        return -1;
                    }
                    else if (b1 < a1) {
                        return 1;
                    }
                    return 0;
                });
            }
            else {
                this.teams.sort(function(a, b) {
                    let a1 = a.name.toLowerCase();
                    let b1 = b.name.toLowerCase();
                    if (a1 < b1) {
                        return 1;
                    }
                    else if (b1 < a1) {
                        return -1;
                    }

                    return 0;
                });
            }
        },
        gotoPage(page) {
            var page_input = document.getElementById('pagination_input');

            if (page_input.value > this.last_page) {
                page_input.value = this.last_page;
            }
            else if (page_input.value < 1) {
                page_input.value = 1;
            }

            this.current_page = page_input.value;
            page_input.blur();
        },
        isNumber(e) {
            var event = e || window.event;
            var keyCode = event.keyCode || event.which;
            var key = String.fromCharCode(keyCode);

            if (key.length == 0) return;

            if(keyCode == 188 || keyCode == 190) return;
            else {
                if (isNaN(key)) {
                    event.returnValue = false;                
                    if (event.preventDefault) event.preventDefault();
                }
            }    
        },
        convertToCard(team) {
            return {
                sub_heading: team.lead_name,
                heading: team.name,
                details: team.description
            };
        },
        trim(str, def = '') {
            if (str) {
                return str.trim();
            }
            return '';
        }
    }
}
</script>

<style scoped>
    .main-con {
        padding: 158px 334px 150px 84px;
    }

    .a-card {
        margin: 0 0.7%;
    }

    .titlebar-con {
        padding-right: 334px;
    }

    .a-header {
        letter-spacing: 0.024em;
        color: #383A3D;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 32px;
    }

    .a-header span {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #383A3D;
        margin-top: 8px;
    }

    .add-new-con {
        position: relative;
        text-align: left;
        padding: 20px 15px 0;
    }

    .add-new-con.side-padding {
        padding-right: 368px;
    }

    .add-new-con h5 {
        font-weight: 500 !important;
        color: #515365;
        line-height: 24px;
        font-size: 24px;
        margin-bottom: 17px;
    }

    .add-new-con img,
    .add-new-con .availability-card-con {
        position: absolute;
        right: 20px;
        top: 58%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    #user-assignment-modal .campaign-img {
        position: relative;
        top: -4px;
        height: 40px;
        width: 40px;
    }

    #user-assignment-modal h5 {
        font-size: 16px;
        color: #515365;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 83.7%;
    }

    #user-assignment-modal h5 span {
        font-size: 12px;
    }

    #user-assignment-modal .left-con,
    #user-assignment-modal .right-con {
        max-width: 400px;
        overflow-y: auto;
    }

    #campaign-assignment-modal .campaign-img {
        position: relative;
        top: -4px;
        height: 40px;
        width: 40px;
    }

    #campaign-assignment-modal h5 {
        font-size: 16px;
        color: #515365;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 83.7%;
    }

    #campaign-assignment-modal h5 span {
        font-size: 12px;
    }

    #campaign-assignment-modal .left-con,
    #campaign-assignment-modal .right-con {
        max-width: 400px;
        overflow-y: auto;
    }

    .a-modal-seller-info-item {
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        padding: 15px 50px 13px;
        word-break: break-all;
        letter-spacing: 0.25px;
        border-radius: 4px;
        position: relative;
        text-align: left;
        color: #141517;
        font-size: 14px;
        min-height:68px;
        opacity: 1;
    }

    .a-modal-seller-info-item:hover {
        background: #FFFFFF 0% 0% no-repeat padding-box;
    }

    .user-con:hover,
    .user-con:hover .a-modal-seller-info-item {
        background-color: #F5F5F5;
    } 

    .a-modal-seller-info-item h5 {
        letter-spacing: 0.24px;
        font-weight: 500;
        color: #141517;
        font-size: 14px;
        margin:0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }

    .a-modal-seller-info-item h5 span {
        letter-spacing: 0;
        font-weight: 400;
        margin-top: 5px;
        font-size: 12px;
        display: block;
    }

    .a-modal-seller-info-item i, .a-modal-seller-info-item img {
        -webkit-transform:translateY(-50%);
        transform: translateY(-50%);
        position:absolute;
        font-size: 32px;
        left:15px;
        top:47%;
    }

    .a-modal-seller-info-item img {
        width: 24px;
    }

    .a-modal-seller-info-item .remove-btn img,
    .a-modal-seller-info-item .assign-btn img {
        -webkit-transition: opacity .5s ease;
        transition: opacity .5s ease;
        opacity: 0;
        left: initial;
        right: 15px;
        width: 24px;
        cursor: pointer;
    }

    .a-modal-seller-info-item .assign-btn img {
        right: 44px;
    }

    .a-modal-seller-info-item:hover .remove-btn img,
    .a-modal-seller-info-item:hover .assign-btn img {
        opacity: 1;
    }

    .a-modal-seller-info-item .remove-btn:hover img,
    .a-modal-seller-info-item .assign-btn:hover img {
        opacity: 0.5;
    }

    .empty-user-con {
        position:relative;
        height: 28vh;
    }

    .empty-user-con img {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        width: 35%;
        left: 50%;
        top: 102%;
    }

    .order-by-buttons button.active,
    .sort-by-buttons button.active {
        background: #9B708F;
        color: #fff;
    }

    .user-filters-con {
        width: 238px;
        max-width: 100%;
    }

    .remove-confirm-text {
        margin-bottom: 0;
        font-size: 10px;
    }
    
    .remove-confirm-btn {
        -webkit-transition: opacity .5s ease;
        transition: opacity .5s ease;
        display: inline-block;
        width: 24px;
        cursor: pointer;
    }

    .remove-confirm-btn img {
        width: 100%;
    }
    
    .remove-confirm-btn:hover {
        opacity: 0.5;
    }

    .remove-confirm-con,
    .remove-confirm-con .a-modal-seller-info-item {
        background: #eaeef5;
    }

    .pagination-footer {
        background: #fff;
        position: fixed;
        padding: 10px 340px 0 80px;
        height: 60px;
        width: 100%;
        bottom: 0;
        left: 0;
    }
</style>
